import {red} from '@mui/material/colors';
import {createTheme} from '@mui/material/styles';

const theme = createTheme({
    palette: {
        mode: "dark",
        primary: {
            main: '#e62f8b',
        },
        secondary: {
            main: '#2fe68a',
        },
        error: {
            main: red.A400,
        },
    },
});

export default theme;
